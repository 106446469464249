import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/logos.css";

const Logos = ({ pageTitle }) => {
  let classes = "logos";
  if (pageTitle === "About Page") {
    classes = classes + " aboutlogos";
  }

  return (
    <div className={classes}>
      <StaticImage
        alt="Kohinoor"
        src="../images/logo/4.png"
        className="image"
      />

      <StaticImage
        alt="Kohinoor"
        src="../images/logo/8.png"
        className="image"
      />

      <StaticImage
        alt="Kohinoor"
        src="../images/logo/9.svg"
        className="image"
      />

      <StaticImage
        alt="Mittal Group"
        src="../images/logo/6.png"
        className="image"
      />

      <StaticImage
        alt="K Raheja Corp"
        objectFit="contain"
        src="../images/logo/11.png"
        className="image"
      />

      <StaticImage
        alt="Kohinoor"
        src="../images/logo/12.png"
        className="image"
      />

      <StaticImage
        alt="Mahindra"
        src="../images/logo/13.webp"
        className="image mahindra"
      />

      <StaticImage
        alt="Kohinoor"
        src="../images/logo/14.webp"
        className="image"
      />

      <StaticImage
        alt="Kohinoor"
        src="../images/logo/16.png"
        className="image"
      />

      <StaticImage
        alt=""
        objectFit="contain"
        src="../images/logo/18.png"
        className="image aboutgroup"
      />

      <StaticImage
        alt="Kohinoor"
        objectFit="contain"
        src="../images/logo/19.png"
        className="image aboutgroup"
      />
      <StaticImage
        alt="Kohinoor"
        objectFit="contain"
        src="../images/logo/17.png"
        className="image aboutgroup"
      />
      <StaticImage
        alt="Kohinoor"
        src="../images/logo/15.png"
        className="image"
      />
    </div>
  );
};

export default Logos;
