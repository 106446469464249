import * as React from 'react'
import '../styles/acc.css'

const Acc = ( ) => {
  
    return (
      <div className="accomplishments flexthis">
        
      <div><p>2000+</p><p>Completed Projects</p></div>
      <div><p>500+</p><p>Satisfied Clients</p></div>
      <div><p>35+</p><p>Years of Experience</p></div>
      <div><p>70+</p><p>Qualified Employees</p></div>
  </div>
    )
  }
  
  export default Acc