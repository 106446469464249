import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Spacer from "../components/Spacer";
import Logos from "../components/logos";
import Acc from "../components/acc";
import "../styles/about.css";

const AboutPage = () => {
  return (
    <Layout pageTitle="About Page">
      <div className="mainpage aboutpage">
        <div className="landinggrid left aboutgrid1">
          <div className="landingwhitespace"></div>
          <div className="landingwords text valuewords">
            <p>Creative Designs, Efficient Execution</p>
          </div>

          <StaticImage
            alt="Landing About"
            src="../images/about.png"
            className="image landingimage"
          />
        </div>

        <Spacer classes="h50" />
        <h1 className="homebelief text aboutbelief">
          ANA was established in 1985 and has since grown into one of the most
          trusted Architecture firms in Maharashtra.
        </h1>

        <Spacer classes="h50" />

        <Logos pageTitle="About Page"></Logos>

        <Spacer classes="h50" />

        <div className="homegrid2 left">
          <StaticImage
            alt="ANA office"
            src="../images/office/aboutoutdoor.jpg"
            className="image"
          />

          <h2 className="text">
            We, at ANA, believe design is dynamic and powerful enough to promise
            changes in the lives of people.
          </h2>
        </div>

        <Acc></Acc>

        <Spacer classes="h50" />

        <StaticImage
          alt="Pool Kalpatru"
          src="../images/about/pool.png"
          className="image fullwidth mobilecropped"
        />
        <Spacer classes="h50" />

        <p className="homebelief text aboutbelief2">
          Creating spaces that can influence those changes is what inspires us
          every day.
        </p>

        <Spacer classes="h50" />

        <div className="grid3 aboutgrid">
          <StaticImage
            alt="Avinash Nawathe"
            src="../images/about/AvinashNawathe.png"
            className="image landingimage"
          />

          <article className="h2 centerthis">
            <h3>Ar. Avinash Nawathe </h3>
            <p>Chairman & Founder</p>
          </article>

          <div className="text">
            With 38 years of experience in Architecture, Architect Avinash
            Nawathe has established ANA and steered his team towards
            indisputable success. With ethics, trust and reliability at his
            core, he has created a legacy that has left a mark in the world of
            Architecture.
          </div>
        </div>

        <Spacer classes="h30" />

        <div className="grid3 aboutgrid left">
          <StaticImage
            alt="Anuja Nawathe"
            src="../images/about/AnujaNawathe.png"
            className="image landingimage"
          />
          <article className="h2 centerthis">
            <h3>Ar. Anuja Nawathe </h3>
            <p>Director, Design</p>
          </article>

          <div className="text">
            With a decade of experience in Architecture and designing, Anuja
            finds her happiness in the joy of creation that architecture brings.
            For her, designing homes is more about designing experiences. She
            has received a number of accolades for female entrepreneurship.
          </div>
        </div>

        <Spacer classes="h30" />

        <div className="grid3 aboutgrid">
          <StaticImage
            alt="Anish Nawathe"
            src="../images/about/AnishNawathe.png"
            className="image landingimage"
          />

          <article className="h2 centerthis">
            <h3>Anish Nawathe</h3>
            <p>Director, Business Development</p>
          </article>

          <div className="text">
            With 7 years of experience in government liasioning, Anish has
            developed a knack for understanding and cultivating great client
            relationships and the need for including latest technology in their
            projects. He has an innate understanding of operations and
            management. Anish is the recipient of Individual Leadership Awards
            and is looking for great future opportunities for ANA.
          </div>
        </div>

        <Spacer classes="h50" />

        <p className="homebelief text aboutbelief3">
          With a legacy of over three decades, ANA has completed 2000+ projects
          successfully and developed numerous client relationships that are
          still going strong.
        </p>

        <Spacer classes="h50" />
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title="About" />;

export default AboutPage;
